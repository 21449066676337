import React, { useState } from 'react'
import './products.style.css'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { obtenerProductsAction } from '../../redux/productsDucks'

const Products = () => {

    const dispatch = useDispatch()
    let products = useSelector(store => store.products.listaProducts)
    const loading = useSelector(store => store.products.loading)
    
    const [filtro, setfiltro] = useState("")

    let re = new RegExp(filtro, "i");



    React.useEffect(() => {
        const fetchData = () => {
            dispatch(obtenerProductsAction())
        }
        fetchData()
    }, [dispatch])



    return !loading ? (
        <div className="products">
            <div className="products__sidebar">
                <h4 className="products__sidebar--titulo">Filtro: </h4>
                <br />
                <input type="text" placeholder="Nombre producto" className="search__input"
                    onChange={(e) => { setfiltro(e.target.value) }} />
                <br /><br /><br />
            </div>

            <div className="products__content">

                {products ? (products.map((row) => {

                    return(
                            re.test(row.titulo) || re.test(row.descripcion) ? (
                                <div className="products__card" key={row.id}>
                                    <div className="products__cards--imageContent">
                                        <img src={row.imagenurl} alt="" className="products__cards--image" />
                                    </div> <br />
                                    <div className="products__cards--title">
                                        {row.titulo}
                                    </div>
                                    {
                                        row.precio !== 0 && (
                                            <div className="products__cards--price">
                                                {row.precio}
                                            </div>
                                        )
                                    }
                                    <div className="products__cards--details">
                                        {row.descripcion}
                                    </div>
                                </div>
                         ) : null
                        
                    )

                    

                })) : null
                }

            </div>
        </div>
    ) : (
        <div className="container border m-5 text-center">
            <div className="spinner-grow text-info m-5" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Products