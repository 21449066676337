import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'

import {crearProductsAction, modificarProductsAction, eliminarProductsAction} from '../../redux/productsDucks'
import styles from './style.module.scss'

const ModalProducts = (props) => {
    
    const dispatch = useDispatch()

    const [titulo, setTitulo] = React.useState("")
    const [precio, setPrecio] = React.useState(0)
    const [descripcion, setDescripcion] = React.useState("")
    const [imagen, setImagen] = React.useState("")

    const [accion, setAccion] = React.useState("")
    const [error, setError] = React.useState("")

    React.useEffect(() => {
        if (props.product) {
            setAccion('actualizar')
            setTitulo(props.product.titulo)
            setPrecio(props.product.precio)
            setDescripcion(props.product.descripcion)
        } else {

        }
    }, [props])

    const accionFormProducts = (cerrarModal) => {
        if (!titulo.trim() || !precio.trim() || !descripcion.trim()) {
            setError('Alguno de los campos esta vacio')
            return
        }
        if( imagen === "" ){
            setError('Seleccione una imagen, de preferencia con formato jpg o png')
            return
        }
        
        const productsData = {
            titulo: titulo,
            precio: precio,
            descripcion: descripcion,
            imagenurl: imagen,
        }

        if(accion === 'actualizar'){
            dispatch(modificarProductsAction(productsData, props.product.id))
            setError("")
            cerrarModal()
        }else{
            dispatch(crearProductsAction(productsData))
            setError("")
            cerrarModal()
        }
    }
    const eliminarProducts = (cerrarModal) => {
        dispatch(eliminarProductsAction(props.product.id))
        setError("")
        cerrarModal()
    }

    const nodo = (
        <div className={styles.modalContainer}>
            <div className={styles.modalBox}>
                <h4>
                    {
                        accion === 'actualizar' ? 'Modificar producto' : 'Crear producto'
                    }
                </h4>
                {
                    error !== "" &&
                    <div className="alert alert-warning mt-3">
                        {error}
                    </div>
                }
                <div className="form-group form-row">
                    <div className="col-12 mb-4">
                        <input type="text"
                            className="form-control"
                            id="titulo_products"
                            placeholder="Nombre del producto"
                            value={titulo}
                            onChange={e => setTitulo(e.target.value)} />
                    </div>
                    <div className="col-12 mb-4">
                        <input type="text"
                            className="form-control"
                            id="subtitulo_products"
                            placeholder="precio"
                            value={precio}
                            onChange={e => setPrecio(e.target.value)} />
                    </div>
                    <div className="col-12 mb-4">
                        <input type="text"
                            className="form-control"
                            id="posicion_products"
                            placeholder="Descripción"
                            value={descripcion}
                            onChange={e => setDescripcion(e.target.value)} />
                    </div>
                </div>

                <div className="form-group mb-4">
                    <label htmlFor="imagenurl_input">Imagen</label>
                    {
                        accion === 'actualizar' && <div>
                            <img src={imagen} alt="..." className={styles.imagen_modal} />
                            <br />
                        </div>
                    }
                    <input type="file"
                        className="form-control-file"
                        id="imagenurl_productss"
                        onChange={e => setImagen(e.target.files[0])} />
                </div>

                <div className="form-group">
                    <button type="submit"
                        className="btn btn-outline-info"
                        onClick={() => accionFormProducts(props.onClose)}>
                        {
                            accion === 'actualizar' ? 'Modificar' : 'Crear'
                        }
                    </button>
                    {
                        accion === 'actualizar' && <button className="btn btn-outline-danger ml-2"
                                                           onClick={() => eliminarProducts(props.onClose)}>
                                                            Eliminar
                                                    </button>
                    }
                    <button className="btn btn-outline-dark float-right"
                        onClick={props.onClose}>
                        Salir
                    </button>
                </div>
            </div>
        </div>
    )

    return ReactDOM.createPortal(nodo, document.getElementById("modal-root"))

}

export default ModalProducts
