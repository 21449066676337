import React from 'react'

import CarouselInicio from './Carousel'

const Home = () => (
  <content className="App-content mb-3">
    <CarouselInicio />
  </content>
)


export default Home
