import { db, storage } from '../firebase'

// constantes
const dataInicial = {
    loading: false,
    error: false
}

// Nombre de las acciones
const LOADING = 'LOADING'
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
const SET_PRODUCTS_SUCCESS = 'SET_PRODUCTS_SUCCESS'
const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS'
const PUT_PRODUCTS_SUCCESS = 'PUT_PRODUCTS_SUCCESS'
const ERROR_PRODUCTS = 'ERROR_PRODUCTS'


export default function productsReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return {...state, loading: true }
        case GET_PRODUCTS_SUCCESS:
            return {...state, loading: false, listaProducts: action.payload }
        case SET_PRODUCTS_SUCCESS:
            return {...state, loading: false, }
        case PUT_PRODUCTS_SUCCESS:
            return {...state, loading: false, }
        case DELETE_PRODUCTS_SUCCESS:
            return {...state, loading: false, }
        case ERROR_PRODUCTS:
            return {...state, error: action.error }
        default:
            return {...state }
    }
}


export const obtenerProductsAction = () => async(dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const data = await db.collection("products").get()
        const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        dispatch({
            type: GET_PRODUCTS_SUCCESS,
            payload: arrayData
        })
    } catch (e) {
        dispatch({
            type: ERROR_PRODUCTS
        })
    }

}

//Esto se puede mejorar con el getState y modificando unicamente el campo 
export const crearProductsAction = (productsData) => async(dispatch) => {
    dispatch({
        type: LOADING
    })
    try {
        const imagenRef = await storage.ref().child('img_products').child(productsData.imagenurl.name)
        await imagenRef.put(productsData.imagenurl)

        const imagenURL = await imagenRef.getDownloadURL()
        productsData.imagenurl = imagenURL

        await db.collection('products').doc().set(productsData)

        const data = await db.collection('products').get()
        const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        dispatch({
            type: SET_PRODUCTS_SUCCESS,
        })
        dispatch({
            type: GET_PRODUCTS_SUCCESS,
            payload: arrayData
        })

    } catch (e) {
        dispatch({
            type: ERROR_PRODUCTS,
            error: e
        })
    }
}

//Igual con todo esto 
export const modificarProductsAction = (productsData, id) => async(dispatch) => {
    dispatch({
        type: LOADING
    })

    let imagenURL = productsData.imagenurl

    try {
        try {
            const imagenRef = await storage.ref().child('img_products').child(productsData.imagenurl.name)
            await imagenRef.put(productsData.imagenurl)
            imagenURL = await imagenRef.getDownloadURL()
        } catch (error) {
            imagenURL = productsData.imagenurl
        }

        await db.collection('products').doc(id).update({
            titulo: productsData.titulo,
            precio: productsData.precio,
            descripcion: productsData.descripcion,
            imagenurl: imagenURL,
        })

        const data = await db.collection('products').get()
        const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        dispatch({
            type: PUT_PRODUCTS_SUCCESS
        })
        dispatch({
            type: GET_PRODUCTS_SUCCESS,
            payload: arrayData
        })
    } catch (error) {
        dispatch({
            type: ERROR_PRODUCTS
        })
    }
}

//Mejoralo plis
export const eliminarProductsAction = (id) => async(dispatch) => {
    dispatch({
        type: LOADING
    })
    try {

        await db.collection('products').doc(id).delete()

        dispatch({
            type: DELETE_PRODUCTS_SUCCESS
        })

        const data = await db.collection('products').get()
        const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        dispatch({
            type: GET_PRODUCTS_SUCCESS,
            payload: arrayData
        })


    } catch (error) {
        console.log(error)
        dispatch({
            type: ERROR_PRODUCTS
        })
    }
}